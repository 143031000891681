// import Vue from 'vue'

// initial state
const state = {
  cp_default: null,
  cp_name: null,
}

// getters
const getters = {
  cp_default: state => state.cp_default,
  cp_name: state => state.cp_name,
}

// actions
const actions = {
  updateCpDefault({ commit }, value) {
    commit('setCpDefault', value);
  },
  updateCpName({ commit }, value) {
    commit('setCpName', value);
  }
}

// mutations
const mutations = {
  setCpDefault(state, value) {
    state.cp_default = value;
  },
  setCpName(state, value) {
    state.cp_name = value;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
