import { createStore } from 'vuex';

import user from './modules/user.js'
import bestellungen from './modules/bestellungen.js'
import abrufbestellungen from './modules/abrufbestellungen.js'
import forecast from './modules/forecast.js'
import variables from './modules/variables.js'
import msbs from './modules/msbs.js'
import ruckversand from './modules/ruckversand.js';

const store = createStore({
  modules: {
    user,
    bestellungen,
    ruckversand,
    forecast,
    variables,
    msbs,
    abrufbestellungen
  },
})


export default store;
