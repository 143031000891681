import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  forecasts: [],
  loading: false,
  error: null
}

// getters
const getters = {
  forecast: (state) => (id) => {
    return state.forecasts.find(forecast => forecast.id === id)
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  loadForecasts ({commit}) {
    commit('setLoading', true)
    console.log('Forecasts laden...')
    firebase.firestore().collection('forecasts')
    .onSnapshot(snapshot => {
      const forecasts = []
      snapshot.forEach(doc => {
        forecasts.push({
          ...doc.data(),
          id: doc.id
        })
        commit('setLoadedForecasts', forecasts)
        commit('setLoading', false)
      })
    }, (error) => {
      console.log(error)
      commit('setLoading', false)
    })
  },
  // eslint-disable-next-line
  createForecast ({commit, getters}, payload) {
    firebase.firestore().collection('forecasts').add(payload)
      .catch((error) => {
        console.log(error)
      })
  // Reach out to firebase and store it
  },
  deleteForecastData ({commit}, payload) {
    commit('setLoading', true)
    firebase.firestore().collection('forecasts').doc(payload).delete()
    .then(() => {
      commit('setLoading', false)
      commit('deleteForecast', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
    commit('setLoading', false)
  },
  updateForecastData ({commit}, payload) {
    commit('setLoading', true)
    const updateObj = {}
    Object.keys(payload).forEach(key => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key]
      }
    })
    firebase.firestore().collection('forecasts').doc(payload.id).update(updateObj)
    .then(() => {
      commit('setLoading', false)
      commit('updateForecast', payload)
    })
    .catch(error => {
      console.log(error)
      commit('setLoading', false)
    })
  }
}

// mutations
const mutations = {
  setLoadedForecasts (state, payload) {
    state.forecasts = payload
  },
  createForecast (state, payload) {
    state.forecasts.push(payload)
  },
  updateForecast (state, payload) {
    const forecast = state.news.find(forecast => {
      return forecast.id === payload.id
    })
    Object.keys(payload).forEach(key => {
      if (payload[key]) {
        forecast[key] = payload[key]
      }
    })
  },
  deleteForecast (state, payload) {
    let array = state.forecasts
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1)
      }
    }
    state.forecasts = array
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
