// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// import '@/styles/main.scss'
import { aliases, md } from 'vuetify/iconsets/md'
import { VDateInput } from 'vuetify/labs/VDateInput'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  defaults: {
    VBtn: {
      style: 'text-transform: none;',
    },
  },
  components: {
    VDateInput,
  },
  icons: {
    defaultSet: 'md',
    aliases,
    sets: {
      md,
    },
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          primary: '#110c48',
          secondary: '#FFAD32',
          spaceBlue: '#0E0A3B',
          darkBlue: '#0B082E',
          energyYellow: '#FFAD32',
          energyRed: '#FF5F5F',
          doveGrey: '#ACB0FB',
          blueprintBlue: '#5960F6',
          steelGrey: '#86849D'
        }
      },
      dark: {
        colors: {
          primary: '#110c48',
          secondary: '#FFAD32',
          warning: '#ff5f5f' 
        }
      }
    }
  },
})