<template>
  <span>
    <v-btn @click="isActive = true" color="secondary" variant="outlined">Neuen CP erstellen</v-btn>
    <v-dialog width="30%" scrollable v-model="isActive" persistent>
      <v-card :rounded="mobil ? '' : 'xl'"
        style="border: 1px solid #5960F6;">
        <v-card-title class="text-energyRed d-flex align-center justify-space-between bg-darkBlue">
          CP erstellen
          <v-btn icon variant="outlined" size="small" color="energyRed" @click="isActive = false" class="mr-3">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Channel Partner-ID*" hint="Format: CP00000 (5 Stellige Zahl aufsteigend.)" v-model="userData.channel_partner_id" variant="outlined" density="compact"></v-text-field>
          <v-text-field label="Legalname*" v-model="userData.legalname" variant="outlined" density="compact"></v-text-field>
          <v-text-field label="E-Mail_CP*" v-model="userData.e_mail" variant="outlined" density="compact"></v-text-field>
          <v-text-field label="Passwort" v-model="userData.password" variant="outlined" density="compact"></v-text-field>
        </v-card-text>
        <v-card-actions v-if="error.show">
          <v-alert type="error">
            {{ error.message }}
          </v-alert>
        </v-card-actions>
        <v-card-actions class="justify-center">
          <v-btn variant="outlined" :disabled="!(userData.channel_partner_id && userData.legalname && userData.e_mail && userData.password)" @click="createCP()" :loading="loading">Erstellen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase/compat/app'

export default {
  name: 'userVerwaltung',
  data: () => ({
    isActive: false,
    userData: {},
    loading: false,
    error: {
      show: false,
      message: ''
    }
  }),
  computed: {
    mobil() { return this.$vuetify.display.smAndDown },
  },
  methods: {
    async createCP() {
      this.loading = true
      const dataHS = {
        channel_partner_id: this.userData.channel_partner_id,
        legalname: this.userData.legalname,
        e_mail: this.userData.e_mail,
      }
      const dataFB = {
        displayName: this.userData.legalname,
        email: this.userData.e_mail,
        password: this.userData.password,
      }
      const res = await firebase.functions().httpsCallable('createCP')({ dataHS, dataFB })
      console.log(res)
      if (res.data.success) {
        this.loading = false
        this.userData = {}
        this.isActive = false
        this.$emit('loadData')
      } else {
        this.loading = false
        this.error = {
          show: true,
          message: 'Entweder ist die Channel-Partner-ID schon vergeben oder die Mailadresse wurde schon verwendet. Bitte prüfe die Daten.'
        }
      }
    },
    async editUser() {
      // Logik zum Bearbeiten eines Benutzers
    },
  }
}
</script>