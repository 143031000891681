<template>
  <v-app>
    <v-app-bar v-if="CPDefault" color="warning" class="px-8">
      <v-icon size="48" class="mr-8">warning</v-icon>
        Admin-Ansicht für den CP {{ CPName }}. <br>Alle Aktionen werden mit diesem CP verknüpft!
        <v-spacer></v-spacer>
        <v-btn class="ml-6" @click="abmelden()" variant="outlined">Zum Test-CP wechseln</v-btn>
      </v-app-bar>
    <spotHeader />
    <v-main class="">
      <router-view />
    </v-main>
    <v-footer class="bg-grey-lighten-4">
      <v-row>
        <v-col cols="12" class="text-center">
          <v-card-title>Hilfe & Kontakt</v-card-title>
          <v-card-text><a href="mailto:support@spotmyenergy.de?subject=Hilfe im Partnerportal">support@spotmyenergy.de</a>
          </v-card-text>
        </v-col>
        <v-col cols="12" class="text-center">
          &copy;{{ new Date().getFullYear() }} SpotmyEnergy
        </v-col>
      </v-row>
    </v-footer>
    <contactNavbar />
  </v-app>
</template>

<script>
import spotHeader from './components/ui/header.vue'
import contactNavbar from './components/ui/contactNavbar.vue'
export default {
  name: 'App',
  data: () => ({
    //
  }),
  components: {
    spotHeader,
    contactNavbar,
  },
  computed: {
    CPDefault() {
      return this.$store.state.variables.cp_default
    },
    CPName() {
      return this.$store.state.variables.cp_name
    },
  },
  methods: {
    abmelden () {
      this.$store.dispatch('variables/updateCpDefault', undefined);
      this.$store.dispatch('variables/updateCpName', undefined);
    }
  }
}
</script>

<style>
.container {
  max-width: 1200px;
}
</style>
