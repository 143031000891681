import store from '../store'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default (to, from, next) => {
  if (store.state.user.user) {
    if (!firebase.auth().currentUser.emailVerified) {
      // next('/verifizieremail')
      next()
    } else {
      next()
    }
  } else {
    if (to.path) {
      next('/login?link=' + to.path)
    } else {
      next('/')
    }
    
  }
}
