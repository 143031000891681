<template>
  <v-app-bar scroll-behavior="elevate" color="spaceBlue" :height="mobil ? '0' : '140'" class="px-8">
    <template v-slot:prepend>
      <v-btn to="/" icon class="rounded-circle" height="100" width="100">
        <v-img to="/" src="@/assets/logo-spotmyenergy.svg" height="100" width="100" contain></v-img>
      </v-btn>
    </template>
    <template v-slot:append>
      <v-btn to="/" color="textMedium" rounded="pill" class="px-2">Dashboard</v-btn>
      <v-btn to="/bestellungen" color="textMedium" rounded="pill" class="px-2">Komponenten bestellen</v-btn>
      <dialogUserVerwaltung v-if="(currentUser?.uid === '6xk73H6soMRyBCIrcpgE3DoQgyL2') || (currentUser?.uid === 'PwSbABpuqLCM9hqeEWWyXhPMCeEf')"/>
      <v-menu v-if="currentUser" left bottom offset-y max-width="300px" class="mb-0">
        <template v-slot:activator="{ props }">
          <v-btn variant="outlined" color="textMedium" rounded="pill" v-bind="props" class="px-2">
            {{ currentUser.email }}
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>
        </template>

        <v-list class="ma-0 pa-0">
          <v-list-item><v-list-item-title>Angemeldet als:<br>{{ currentUser.displayName }}</v-list-item-title></v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="onLogout">
            <v-list-item-title>Abmelden</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn to="/login" rounded="pill" variant="outlined" v-else>Login</v-btn>
    </template>
  </v-app-bar>
</template>

<script>
// import firebase from 'firebase/compat/app'
import dialogUserVerwaltung from '../../pages/userverwaltung.vue'
export default {
  name: 'contactNavbar',
  data: () => ({
    showNavBar: false,
    isLocalhost: false,
  }),
  components: {
    dialogUserVerwaltung
  },
  watch: {
    '$store.state.user.user'(newValue) {
      if (newValue) {
        console.log(newValue)
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.user.user ?? {}
    }
  },
  methods: {
    onLogout() {
      this.$store.dispatch('user/logout')
      this.$router.push('/login')
    },
    checkIfLocalhost() {
      // Check if the domain is localhost
      if ((window.location.hostname === "localhost") || (process.env.VUE_APP_ENV === 'beta')) {
        this.isLocalhost = true;
      } else {
        console.log(false);
        this.isLocalhost = false;
      }
    }
  },
  created() {
    this.checkIfLocalhost();
  }
}
</script>
