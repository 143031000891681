import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// initial state
const state = {
  user: null,
  userAccount: null,
  loading: false,
  error: null
}

// getters
const getters = {
  user (state) {
    return state.user
  },
  loading (state) {
    return state.loading
  },
  error (state) {
    return state.error
  }
}

// actions
const actions = {
  signUserUp ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
      .then(
        user => {
          commit('setLoading', false)
          const newUser = {
            id: user.uid
          }
          commit('setUser', newUser)
          return newUser
        }
      )
      .catch(
        error => {
          commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
  },
  signUserIn ({ commit }, payload) {
    commit('setLoading', true)
    commit('clearError')
    console.log('signUserIn')
    return new Promise((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(user => {
          console.log(user);
          commit('setLoading', false);
          commit('setUser', user.user);
          resolve(user); // Erfolgreiches Login
        })
        .catch(error => {
          commit('setLoading', false);
          commit('setError', error);
          console.log(error);
          reject(error); // Fehler beim Login
        });
    });
  },
  autoSignIn ({commit}, payload) {
    console.log('autoSignIn')
    commit('setUser', payload)
  },
  logout ({commit}) {
    console.log('logout')
    firebase.auth().signOut()
    commit('setUser', null)
  },
  clearError ({ commit }) {
    commit('clearError')
  }
}

// mutations
const mutations = {
  setUser (state, payload) {
    state.user = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setError (state, payload) {
    state.error = payload
  },
  clearError (state) {
    state.error = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
