import { createApp } from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import firebase from '../firebase.js'
import store from './store';
import './styles/fonts.css';

loadFonts()

const app = createApp(App);

// Globale Funktion definieren
app.config.globalProperties.$filters = {
  money(value) {
    const formatter = Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR'
    });
    return formatter.format(value);
  },

  date(value, format = 'DDMMYYYY hh:mm:ss') {
    const date = new Date(value);
    if (isNaN(date)) {
      return "-";
    }
  
    // Zerlege das Datum in seine Bestandteile
    const day = String(date.getDate());
    const month = String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    const shortYear = String(date.getFullYear()).slice(2);
  
    // Zerlege die Uhrzeit in ihre Bestandteile
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    // Arrays für Wochentage und Monate in Deutsch
    const weekdaysShort = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
    const weekdaysLong = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
    const monthsShort = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'];
    const monthsLong = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
  
    // Bestimme den Wochentag und Monat als Index
    const weekdayIndex = date.getDay();
    const monthIndex = date.getMonth();
  
    // Ersetze die Platzhalter im Format-String
    return format
      .replace('DDDD', weekdaysLong[weekdayIndex])
      .replace('DDD', weekdaysShort[weekdayIndex])
      .replace('DD', day.padStart(2, '0'))
      .replace('MMMM', monthsLong[monthIndex])
      .replace('MMM', monthsShort[monthIndex])
      .replace('MM', month.padStart(2, '0'))
      .replace('YYYY', year)
      .replace('YY', shortYear)
      .replace('hh', hours)
      .replace('mm', minutes)
      .replace('ss', seconds);
  }
};

app
  .use(vuetify)
  .use(firebase)
  .use(store)
  .use(router)
  .mount('#app')
  
// onAuthStateChanged in die created()-Funktion integrieren
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch('user/autoSignIn', user);
  }
  if (firebase.auth().currentUser && firebase.auth().currentUser.emailVerified) {
    // store.dispatch('kunden/loadKunden');
  }
});