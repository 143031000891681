import { createWebHistory, createRouter } from "vue-router";
import authGuard from './auth-guard'

const routes =  [
  {
    path: "/",
    name: "dashboard",
    beforeEnter: authGuard,
    component: () => import("../pages/dashboard")
  },
  {
    path: "/installateure",
    name: "installateure",
    beforeEnter: authGuard,
    component: () => import("../pages/installateure")
  },
  {
    path: "/bestellungen",
    name: "bestellungen",
    beforeEnter: authGuard,
    component: () => import("../pages/bestellungen")
  },
  {
    path: "/form/auftragsanmeldung",
    name: "auftragsanmeldung",
    beforeEnter: authGuard,
    component: () => import("../pages/formulare/auftragsanmeldung")
  },
  {
    path: "/form/zaehlerwechsel",
    name: "zaehlerwechsel",
    beforeEnter: authGuard,
    component: () => import("../pages/formulare/zaehlerwechsel")
  },
  {
    path: "/form/terminverschieben",
    name: "terminverschieben",
    beforeEnter: authGuard,
    component: () => import("../pages/formulare/terminVerschieben")
  },
  {
    path: "/form/fertigmeldung",
    name: "fertigmeldung",
    beforeEnter: authGuard,
    component: () => import("../pages/formulare/fertigmeldung")
  },
  {
    path: "/fertigmeldung",
    name: "fertigmeldungExtern",
    component: () => import("../pages/formulare/fertigmeldung_installateure.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../pages/login")
  },
  {
    path: "/passwortvergessen",
    name: "passwortvergessen",
    component: () => import("../pages/passwortVergessen")
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../pages/login")
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/', // Weiterleitung auf die Startseite
  }
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes,
});

export default router;